import { yupResolver } from "@hookform/resolvers/yup";
import BorderColor from "@mui/icons-material/BorderColor";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Link,
  Checkbox,
  IconButton,
} from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { serverTimestamp } from "firebase/firestore";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { adminUid } from "../common/Config/AdminConfig";
import {
  createLonpa,
  setUserPrivateCreateLonpa,
} from "../common/Helper/FirebaseHelper";
import {
  pointSubAutoCreation2500,
  pointSubAutoCreation600article,
  checkisSubscription,
} from "../common/Helper/PointHelper";
import { userApp } from "../common/firebase";
import { NORMAL_CHARACTER } from "../constants/regex";
import {
  VALIDATION_ERR_REQUIRED,
  VALIDAtION_INVALID_CHARACTER,
  VALIDATION_LONG_MAX_LENGTH,
  VALIDATION_LONG_MIN_LENGTH,
} from "../constants/validation";

import LodingModal from "./CommonModal/LodingModal";
import CreateIdeaModal from "./Create/Modal/CreateIdeaModal";
import CreateUsePointModal from "./Create/Modal/CreateUsePointModal";
import Footer from "./components/Footer";

const schema = yup.object({
  claim: yup
    .string()
    .required(VALIDATION_ERR_REQUIRED)
    .max(100, VALIDATION_LONG_MAX_LENGTH)
    .min(4, VALIDATION_LONG_MIN_LENGTH)
    .matches(NORMAL_CHARACTER, VALIDAtION_INVALID_CHARACTER),
});

type Props = {
  bgColor: any;
  setAutoCreate: any;
  styletext: any;
};

const Create: React.VFC<Props> = ({
  bgColor,
  setAutoCreate,
  styletext,
}: Props) => {
  //const classes = useStyles();
  const [claim, setClaim] = useState("");
  //const [togglenum, setToggleNum] = useState(null);
  const [termcheck, setTermcheck] = useState(false);
  const [automake, setAutomake] = useState(false);
  const [automakemovie, setAutomakemovie] = useState(false);
  const [ischeck, setIscheck] = useState({ 0: false, 1: false });
  //const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [lodingmodalopen, setLodingmodalopen] = useState(false);
  const [createUsePointmodalopen, setCreateUsePointmodalopen] = useState(false);
  const [createIdeamodalopen, setCreateIdeamodalopen] = useState(false);
  const [isButtonLock, setIsButtonLock] = useState(false);
  const [adminFlag, setAdminFlag] = useState(false);

  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      claim: "",
    },
  });

  const cellStyle = {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
  };

  //const checkboxcolor = ["#bbbbbb", "#ffbbbb"];

  const handleText = (e: any) => {
    if (e.target.value.length <= 200) {
      //console.log(e.target.value.length)
      setClaim(e.target.value);
      if (e.target.value === "") {
        setIscheck({ ...ischeck, 0: false });
      } else if (e.target.value.length >= 2) {
        setIscheck({ ...ischeck, 0: true });
      }
    }
  };

  const handleChangeTerm = async () => {
    if (termcheck) {
      setTermcheck(false);
      setIscheck({ ...ischeck, 1: false });
    } else {
      setTermcheck(true);
      setIscheck({ ...ischeck, 1: true });

      const userStatus = await checkisSubscription();
      setAdminFlag(userStatus.isSubscribe);
      //ユーザが承認ユーザか，Lonpa作成者か，記録する
      // onAuthStateChanged(getAuth(userApp), async (user) => {
      //   if (user) {
      //     if (adminUid.includes(user.uid)) {
      //       setAdminFlag(true);
      //     }
      //   }
      // });
    }
  };

  const handleChangeAuto = () => {
    if (automake) {
      setAutomake(false);
    } else {
      setAutomake(true);
    }
  };

  const handleChangeAutoMovie = () => {
    if (automakemovie) {
      setAutomakemovie(false);
    } else {
      setAutomakemovie(true);
    }
  };

  const nGrum = (name: string, n: number) => {
    const searchGrums = {};
    for (let i = 0; i < name.length; i++) {
      const results = [name.substr(i, n)];
      results.map((result) => {
        // @ts-ignore
        searchGrums[result] = true;
      });
    }
    return searchGrums;
  };

  async function PostCheck() {
    if (automake || automakemovie) {
      setCreateUsePointmodalopen(true);
    } else {
      onAuthStateChanged(getAuth(userApp), async (user) => {
        if (user) {
          await Post();
        } else {
          alert("アカウント作成が必要です");
          navigate("/Loadmap");
        }
      });
    }
  }

  async function Post() {
    if (!isButtonLock) {
      setIsButtonLock(true);
      setLodingmodalopen(true);

      const docRefCreatedLonpa = async () => {
        const docRef = await createLonpa({
          claim: claim,
          updatedAt: serverTimestamp(),
          createdAt: serverTimestamp(),
          ngram: nGrum(claim, 2),
          agree: 0,
          disagree: 0,
          isopinion: true,
          createdBy: getAuth(userApp).currentUser?.uid,
          isDelete: false,
          imageurl: "",
        });
        //ユーザの作成履歴を記録する
        await setUserPrivateCreateLonpa(docRef.id, {
          claim: claim,
          createdAt: serverTimestamp(),
          isopinion: true,
          parent: "",
          isDelete: false,
        });

        if (automake) {
          if (automakemovie) {
            pointSubAutoCreation2500(docRef.id);
            setAutoCreate({
              create: true,
              id: docRef.id,
              tagCreate: true,
              imageCreate: true,
              craimCreate: true,
              craimAccept: true,
              adCreate: false,
              movieCreate: true,
              counter: 0,
            });
          } else {
            pointSubAutoCreation600article(docRef.id);
            setAutoCreate({
              create: true,
              id: docRef.id,
              tagCreate: true,
              imageCreate: true,
              craimCreate: true,
              craimAccept: false,
              adCreate: false,
              movieCreate: false,
              counter: 0,
            });
          }
        }

        navigate("/Lonpa/" + docRef.id);
      };
      setClaim("");
      window.scrollTo(0, 0);
      await docRefCreatedLonpa();
      setLodingmodalopen(false);
    }
  }

  return (
    <>
      <div style={{ backgroundColor: bgColor.back01 }}>
        <div style={{ height: "6vh" }}></div>

        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: bgColor.back01,
                color: bgColor.text01.fontColor,
              }}
            >
              <Box
                sx={{
                  // alignItems: 'center',
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  color={bgColor.text01.fontColor}
                  gutterBottom
                  variant="h6"
                  fontWeight="bold"
                >
                  <Checkbox
                    //{...label}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked={ischeck[0]}
                    style={{
                      color: ischeck[0] ? bgColor.back03 : bgColor.back02,
                    }}
                  />
                  主張
                  {adminFlag ? (
                    <>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          setCreateIdeamodalopen(true);
                        }}
                      >
                        <HdrAutoIcon fontSize="inherit" />
                      </IconButton>
                    </>
                  ) : (
                    <></>
                  )}
                </Typography>

                <div style={{ height: "1vh" }}></div>

                <Controller
                  control={control}
                  name="claim"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      onChange={(e) => {
                        onChange(e);
                        handleText(e);
                      }}
                      value={value}
                      sx={{
                        input: {
                          color: bgColor.text04.fontColor,
                        },
                      }}
                      style={{
                        border: bgColor.line02,
                      }}
                    />
                  )}
                />
                <Typography>{errors.claim?.message}</Typography>
                <div style={{ height: "6vh" }}></div>
                <Typography
                  color={bgColor.text01.fontColor}
                  gutterBottom
                  variant="h6"
                  fontWeight="bold"
                >
                  <Checkbox
                    //{...label}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked={ischeck[1]}
                    style={{
                      color: ischeck[1] ? bgColor.back03 : bgColor.back02,
                    }}
                  />
                  設定・規約確認
                </Typography>

                <div>
                  <Checkbox
                    style={{
                      color: bgColor.back11,
                    }}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    onClick={handleChangeTerm}
                    checked={termcheck}
                  />
                  <Link
                    color="inherit"
                    href="http://lamact.com/Terms"
                    target="_blank"
                  >
                    Lamact利用規約
                  </Link>
                  に同意します
                </div>

                {termcheck && adminFlag ? (
                  <div>
                    <Checkbox
                      style={{
                        color: bgColor.back11,
                      }}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onClick={handleChangeAuto}
                      checked={automake}
                    />
                    自動生成機能を利用
                  </div>
                ) : (
                  <div
                    style={{
                      color: bgColor.back04,
                    }}
                  >
                    <Checkbox
                      style={{
                        color: bgColor.back04,
                      }}
                      disabled
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onClick={handleChangeAuto}
                      checked={automake}
                    />
                    自動生成機能を利用
                  </div>
                )}

                {automake ? (
                  <>
                    <div>
                      <Checkbox
                        style={{
                          color: bgColor.back11,
                        }}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onClick={handleChangeAutoMovie}
                        checked={automakemovie}
                      />
                      動画まで自動生成
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div style={{ height: "2vh" }}></div>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {!isButtonLock && ischeck[0] && ischeck[1] ? (
                  <>
                    <Button
                      endIcon={<BorderColor />}
                      onClick={handleSubmit(PostCheck)}
                      style={{ color: bgColor.text04.fontColor, fontSize: 20 }}
                    >
                      投稿
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      color="primary"
                      endIcon={<BorderColor />}
                      style={{ color: bgColor.text09.fontColor, fontSize: 20 }}
                      disabled
                    >
                      投稿
                    </Button>
                  </>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <br />
                <br />
              </Box>
            </Grid>
          </Grid>
        </Container>

        {createUsePointmodalopen ? (
          <CreateUsePointModal
            open={createUsePointmodalopen}
            setOpen={setCreateUsePointmodalopen}
            pointPattarn={automakemovie}
            Post={Post}
          />
        ) : (
          <></>
        )}
        {createIdeamodalopen ? (
          <CreateIdeaModal
            open={createIdeamodalopen}
            setOpen={setCreateIdeamodalopen}
          />
        ) : (
          <></>
        )}

        <LodingModal open={lodingmodalopen} setOpen={setLodingmodalopen} />
      </div>
      <Footer bgColor={bgColor} styletext={styletext} isimgshow={true} />
    </>
  );
};

export default Create;
